import { useLocalStorage } from '@vueuse/core'
// const MINOR_RE_DISPLAY_SECONDS = 60 * 60 * 24 * 7 // 1 week

/**
 * This composable checks the backend for the version number of the app and shows a modal if the version is different.
 * It turns into a bigger warning if it's a major version change as that would be breaking.
 * We're using semver so a patch will show a soft warning that doesn't re-appear, a minor will show
 * a soft warning that reappears every time the app is opened, and a major will show a hard warning that
 * reappears every time the app is opened. We don't want to stop users trying to use the app.
 */
export const useNativeVersion = (opts?: { disableCheckOnMount?: boolean }) => {
  const { version: appVersion, hostName } = useRuntimeConfig().public
  const serverVersion = ref<string | null>(null)

  const getServerVersion = async () => {
    try {
      const res = await $fetch<{ version: string }>('/api/check-native-version.json', { baseURL: hostName })
      serverVersion.value = res.version
      return res.version
    } catch (e) {
      console.error(e)
    }
  }

  const update = computed(() => {
    if (!serverVersion.value) {
      console.error('No server version found - nuxt native-version module')
      return { needsUpdate: false, updateType: null }
    }
    if (!appVersion) {
      console.error('No app version found - nuxt native-version module')
      return { needsUpdate: false, updateType: null }
    }

    const [serverMajor, serverMinor, serverPatch] = serverVersion.value.split('.').map(Number)
    const [appMajor, appMinor, appPatch] = appVersion.split('.').map(Number)

    if (serverMajor > appMajor) {
      return { needsUpdate: true, updateType: 'major' }
    } else if (serverMinor > appMinor) {
      return { needsUpdate: true, updateType: 'minor' }
    } else if (serverPatch > appPatch) {
      return { needsUpdate: true, updateType: 'patch' }
    } else {
      return { needsUpdate: false, updateType: null }
    }
  })

  const updateType = computed(() => update.value.updateType)
  const needsUpdate = computed(() => update.value.needsUpdate)

  // has the user dismissed the update
  const localStorageKey = 'native-version-update-dismissed'
  const hasDismissed = useLocalStorage(localStorageKey, false)

  const localStorageTimestampKey = 'native-version-update-dismissed-timestamp'
  const hasDismissedTimestamp = useLocalStorage(localStorageTimestampKey, 0)

  const localStorageDismissedVersionKey = 'native-version-update-dismissed-version'
  const dismissedVersion = useLocalStorage(localStorageDismissedVersionKey, '')

  // has the user closed the modal
  const hasClosed = ref(false)

  const isOpen = computed(() => {
    // if we don't need an update, don't show the modal
    if (!needsUpdate.value) return false

    // if the user has manually closed the modal, don't show it
    if (hasClosed.value) return false

    // HOH Special - if the update is a patch we don't show
    if (updateType.value === 'patch') return false

    // if the update is a minor and they have dismissed it, don't show - hoh doesn't want
    // to show after timeout for minor, just never show again for the same version
    if (
      updateType.value === 'minor' &&
      hasDismissed.value &&
      dismissedVersion.value === serverVersion.value
    ) {
      return false
    }

    // otherwise show the modal - this will show every time the app is opened for
    // major updates and one time when the app is opened for minor updates if the user
    // hasn't dismissed it yet. Each minor update will show once again.
    return true
  })

  const closeModal = () => {
    hasClosed.value = true
    hasDismissed.value = true
    hasDismissedTimestamp.value = Date.now()
    dismissedVersion.value = serverVersion.value
  }

  const simulateUpdateNeeded = (type: 'major' | 'minor' | 'patch') => {
    const [major, minor, patch] = appVersion.split('.').map(Number)
    if (type === 'major') {
      serverVersion.value = `${major + 1}.0.0`
    } else if (type === 'minor') {
      serverVersion.value = `${major}.${minor + 1}.0`
    } else if (type === 'patch') {
      serverVersion.value = `${major}.${minor}.${patch + 1}`
    }
  }

  if (!opts?.disableCheckOnMount) {
    onMounted(async () => {
      await getServerVersion()
    })
  }

  return {
    appVersion,
    serverVersion,
    updateType,
    needsUpdate,
    isOpen,
    hasDismissed,
    hasDismissedTimestamp,
    dismissedVersion,
    localStorageKey,
    localStorageTimestampKey,
    localStorageDismissedVersionKey,

    // methods
    getServerVersion,
    simulateUpdateNeeded,
    closeModal
  }
}
