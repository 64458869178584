<template>
  <UModal v-model="localIsOpen" :ui="{ wrapper: 'relative z-[100]' }">
    <div class="flex flex-col gap-4">
      <div class="flex justify-between space-x-3">
        <div class="text-lg font-bold">Update {{ updateType === 'major' ? 'Required' : 'Available' }}</div>
        <div>
          <UButton
            type="button"
            variant="ghost"
            :padded="false"
            square
            size="lg"
            icon="i-heroicons-x-mark-16-solid"
            @click="closeModal()"
          />
        </div>
      </div>
      <div
        v-if="updateType === 'major'"
        class="bg-red text-white p-2 rounded-md flex items-center justify-center gap-4"
      >
        <div>
          <UIcon name="i-heroicons-exclamation-triangle size-8"></UIcon>
        </div>
        <div>
          <p>Some features of the app may not work until you update</p>
        </div>
      </div>
      <div>
        <p>Your app version is out of date. Please visit the app store and install the latest update.</p>
      </div>

      <div>
        <FrontendAppStoreLinks :hide-ios="isAndroid" :hide-android="isIos" />
      </div>
    </div>
  </UModal>
</template>

<script setup lang="ts">
/**
 * This component checks the backend for the version number of the app and shows a modal if the version is different.
 * It turns into a bigger warning if it's a major version change as that would be breaking.
 * We're using semver so a patch will show a soft warning that doesn't re-appear, a minor will show
 * a soft warning that reappears every time the app is opened, and a major will show a hard warning that
 * reappears every time the app is opened. We don't want to stop users trying to use the app.
 */

const { isAndroid, isIos } = useRuntimeConfig().public

const nativeVersion = useNativeVersion()
const { isOpen, closeModal, updateType } = nativeVersion

const localIsOpen = computed({
  get() {
    return isOpen.value
  },
  set(v) {
    if (!v) closeModal()
  }
})

// Test on dev
// onMounted(async () => {
//   await new Promise((resolve) => setTimeout(resolve, 1000))

//   const { simulateUpdateNeeded, hasDismissed, hasDismissedTimestamp } = nativeVersion
//   hasDismissedTimestamp.value = 0
//   hasDismissed.value = false
//   simulateUpdateNeeded('minor')
// })
</script>
